
import img1 from '../images/layouts/slider1.png'
import img2 from '../images/layouts/slider2.png'
import img3 from '../images/layouts/slider3.png'
import img4 from '../images/layouts/slider4.png'
import image_1 from '../images/layouts/slider-1.png'

import avt from '../images/layouts/mark.jpeg'
import sofia from '../images/layouts/sofia.jpeg'
import Oliver from '../images/layouts/oliver.jpeg'
import olivia from '../images/layouts/olivia.jpeg'
import sofiaa from '../images/layouts/sofiaa.jpeg'






const dataCard = [
    {
        id: 1,
        img: img1,
        title: ' Where Curiosity Meets Technological Wizardry.',
        price : 'AI Demystified',
        // avt: avt,
        // name: 'Mark Peters',
        // tag: '@mark'
    },

    {
        id: 2,
        img: img2,
        title: 'Empower Your Learning with Modules Enhanced by Generative AI.',
        price : 'Gen AI',
        // avt: sofia,
        // name: ' Sona James',
        // tag: '@sona'
    },
    {
        id: 3,
        img: img3,
        title: 'Building Skills with Real AI Tools.',
        price : ' Workshop Immersion',
        // avt: olivia,
        // name: 'Olivia Johnson',
        // tag: '@Olivia'
    },
    // {
    //     id: 3,
    //     img: img3,
    //     title: 'Parenting Phase - IV',
    //     price : 'Raising Kid',
    //     avt: sofiaa,
    //     name: 'Sophia Davis',
    //     tag: '@Sophia'
    // },
    // {
    //     id: 4,
    //     img: img4,
    //     title: 'Adult Phase - V',
    //     price : 'Growing Child',
    //     avt: Oliver,
    //     name: 'Oliver Brown',
    //     tag: '@Oliver'
    // }


]

export default dataCard;