













const dataFaqs = [
    {
        id: 1,
        title: `Can I launch any type of business using Flagship's ecommerce platform?`,
        text: `Yes, Flagship's ecommerce platform is versatile and can accommodate a wide range of business types. Whether you plan to sell physical products or offer services, the platform can be tailored to fit your specific business needs.
        `,
        show: '',
        parent: "AI"

    },
    {
        id: 2,
        title: `Does Flagship provide assistance with marketing and promoting my business?
        `,
        text: `Yes, Flagship understands the importance of marketing for business success. They offer guidance and resources to help you develop effective marketing strategies, including social media marketing, content creation, SEO, and advertising techniques through their mentorship programs, the Flagship Incubator and the Flagship Camp.
        `,
        show: '',
        parent: "AI"

    },
    {
        id: 3,
        title: `What is the Flagship Mentorship Program? 
        `,
        text: `The Flagship Mentorship Program is an entrepreneurship program designed to help students learn the practical aspects of starting and launching a business. Through guided mentorship and hands-on experience, students will develop their business ideas and launch them on our flagship e-commerce platform.
        `,
        show: '',
        parent: "AI"

    },
    {
        id: 4,
        title: `Is the mentorship program only for high school students?
        `,
        text: `Our The mentorship program is open to both every student be it elementary, middle or high school. We believe that entrepreneurship education can benefit students at various stages of their academic journey.
        AI feature is a groundbreaking addition that allows players to train and interact with their virtual babies in a manner similar to raising real children. Through training and guidance, your virtual baby's responses evolve, adapting to your teaching style and nurturing methods.`,
        show: '',
        parent: "AI"

    },
    {
        id: 5,
        title: `Will I have access to the e-commerce platform? 
        `,
        text: `Yes, you will have access to our flagship e-commerce platform. This platform will serve as the launchpad for your business idea, allowing you to create and manage your own online store.
        `,
        show: '',
        parent: "AI"

    },
    {
    id: 6,
    title: `Can I join the mentorship program with a team?
     `,
    text: `Yes, you can join the mentorship program with a team. If you already have a team of students working on a business idea, you can apply together and receive mentorship as a group, but will have to enroll individually.

    `,
    show: '',
    parent: "AI"

},
{
    id: 7,
    title: `How do I apply for the mentorship program? 
     `,
    text: `To apply for the mentorship program, visit our website and fill out the application form. The application may include questions about your entrepreneurial interests, goals, and any previous experience you may have.
    `,
    show: '',
    parent: "AI"

},
{
    id: 8,
    title: `Does Flagship offer any funding opportunities or connections to potential investors? 
     `,
    text: `Flagship provides access to funding opportunities or help connect student entrepreneurs with potential investors. They can offer access to financial resources, as well as necessary connects for partnerships. In the current scenario they mainly offer these services through their incubator program wherein they will help students to prepare their decks, develop their elevator and main pitch and through exclusive webinars with industry leaders who have raised Billions of Dollar in the past we will help them to effectively showcase their products and raise funds. Apart from this students may get a chance to showcase their products through Flagship Camp and Flag Con Competition.
    `,
    show: '',
    parent: "AI"

},
{
    id: 9,
    title: `How do you incorporate AI tools?
     `,
    text: `Through our AI powered seller dashboard we will allow sellers to easily track the most trending products in their product category and even overall and also allow sellers to track consumer psychographic. Through our mentorship program we bring and Incorporate AI into daily operations for effective productivity and time management.
    `,
    show: '',
    parent: "AI"

},






]

export default dataFaqs;