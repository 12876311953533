import React , {useState} from 'react';

import img1 from '../../assets/images/layouts/about-01.png'
import img2 from '../../assets/images/layouts/about-02.png'
import img3 from '../../assets/images/layouts/about-03.png'
import img4 from '../../assets/images/layouts/about-04.png'
import img5 from '../../assets/images/layouts/comming.jpg'
import breathing from '../../assets/images/layouts/breathing.png'

import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : breathing,
            class: 'img1'
        },
        // {
        //     id: 2,
        //     img : img5,
        //     class: 'img2'
        // },
        // {
        //     id: 3,
        //     img : img3,
        //     class: 'img3'
        // },
        // {
        //     id: 4,
        //     img : img4,
        //     class: 'img4'
        // },
        // {
        //     id: 5,
        //     img : img5,
        //     class: 'img5'
        // },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'MaAvatar NFT',
            heading: 'How it works over 45 minutes: ',
            desc1 :' AI What the fuss?',
            desc2: ' Change in curriculum  ' ,
            desc3: ' New wave of career  ' ,
            desc4: ' Navigating, the Flagship way '

        }
    )
    return (
        <section className="about" id="how-it-works">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                            <br/> <br/>
                                <div className="images imagess">
                                   
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="cyfonii" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text-flag" >
                                {/* <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6> */}
                                <h3 className="heading margin-t-85">{dataBlock.heading}</h3>
                                <h6 className="sub-heading"><span>{dataBlock.desc1}</span></h6>
                                <h6 className="sub-heading"><span>{dataBlock.desc2}</span></h6>
                                <h6 className="sub-heading"><span>{dataBlock.desc3}</span></h6>
                                <h6 className="sub-heading"><span>{dataBlock.desc4}</span></h6>
                                    {/* <p className="mb-17 "><span>{dataBlock.desc1}</span></p>
                                    <p className="mb-17 "><span>{dataBlock.desc2}</span></p>
                                    <p className="mb-17 sub-heading"><span>{dataBlock.desc3}</span></p>
                                    <p className="mb-17 sub-heading"><span>{dataBlock.desc4}</span></p> */}
                                    {/* <p className="mb-26">{dataBlock.desc2}</p>
                                    <p className="mb-26">{dataBlock.desc3}</p>
                                    <p className="mb-26">{dataBlock.desc4}</p> */}
                                    <Button link='/about' title='Register ' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;