
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';

import routes from './pages';
import Page404 from './pages/404';
import Header from './components/header/Header';
import Home01 from './pages/Home01';

function App() {

    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);

    return (
        <>

            <Header />

           <Home01 />

    
        </>
    );
}

export default App;
