
import Zach from '../images/layouts/Zach.png'
import Sparsh from '../images/layouts/Sparsh.png'
import Bhavin from '../images/layouts/Bhavin.png'
import Shreya from '../images/layouts/Shreya.png'
import angel from '../images/layouts/Angel.png'
import Singh from '../images/layouts/Singh.png'
import Nassa from '../images/layouts/Nassa.png'

import img4 from '../images/layouts/team-04.png'


const dataTeam = [

    {
        id: 1,
        img: Zach,
        name: 'Zach',
        position: 'Creation Captain',
        video:"https://flagship.club/assets/image/flagship-video5.mp4"
    },
    {
        id: 2,
        img: Sparsh,
        name: 'Sparsh',
        position: 'Tech Prodigy',
        video:"https://flagship.club/assets/image/flagship-video2.mp4"

    },
    {
        id: 3,
        img: Bhavin,
        name: 'Bhavin',
        position: 'Branding Belle',
        video:"https://flagship.club/assets/image/flagship-video3.mp4"

    },

  

    {
        id: 4,
        img: Shreya,
        name: 'Shreya',
        position: 'Gameplay Guru',
        video:"https://flagship.club/assets/image/flagship-video4.mp4"

    },

    // {
    //     id: 5,
    //     img: angel,
    //     name: 'Angel',
    //     position: 'Pixel Picasso'
    // },

    // {
    //     id: 6,
    //     img: Singh,
    //     name: ' Singh',
    //     position: 'Blockchain Alchemist'
    // },

    // {
    //     id: 7,
    //     img: Nassa,
    //     name: 'Nassa',
    //     position: 'Media Magician'
    // },


    

]

export default dataTeam;