
import img1 from '../images/layouts/pic-1.png'
import img2 from '../images/layouts/pic-2.png'
import img3 from '../images/layouts/pic-3.png'
import img4 from '../images/layouts/pic-4.png'


const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Entrepreneur',
        desc: '4x entrepreneurs with multi-decade experience.  '
    },
    {
        id: 2,
        img: img2,
        title: 'Wharton Alumni',
        desc: 'A wharton Mar-Tech staying at the forefront of innovation all her career. '
    },
    {
        id: 3,
        img: img3,
        title: 'Experience',
        desc: 'She has 25+ years of experience in leading the company from the forefront. '
    },
    {
        id: 4,
        img: img4,
        title: 'Tech Lead',
        desc: 'Forefront in developing and adopting innovative technologies  '
    },


]

export default dataPortfolio;