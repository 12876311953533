import React , {useRef, useState} from 'react';

import { Accordion } from 'react-bootstrap-accordion';
import { Link } from 'react-router-dom';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';



function Chat(props) {
    const {data} = props;

    const [dataTab] = useState([
        {
            id: 1,
            title: 'Cryptocurrency',
        },
        {
            id: 2,
            title: 'NFT Token',
        },
        {
            id: 3,
            title: 'Collection',
        },
        {
            id: 4,
            title: 'Crypto Trading',
        },

    ]);
    const [message, setMessage] = useState();
    const [loader, setLoder] = useState(false);

    const [botMessage, setBotMessage] = useState([])
    const handleSubmit = (event)=>{
        setLoder(true)
        event.preventDefault();
        fetch("https://api.khubero.com/maavatar/chat", {
     
        // Adding method type
        method: "POST",
         
        // Adding body or contents to send
        body: JSON.stringify({
          
            message:message
      
        }),
         
        // Adding headers to the request
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        }
      })
      
      // Converting to JSON
      .then(response => response.json())
      
      // Displaying results to console
      .then(json => {
        setLoder(false)
        if(json.success){
          const botMsg = json.message[0].message.content
          const newMwssage = {user:message,bot:botMsg}


    
          setBotMessage((previous)=>[...previous, newMwssage])
        }
        else{
      
        }
      });






   
    //    console.log("divRef", divRef)
    // divRef.current.scrollToView();
     
    }

//     return (
//         <section className="">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="block-text center">
//                                 <h6 className="sub-heading"><span>Chat</span></h6>
//                                 <h3 className="heading">Chat with Your Virtual Baby</h3>
//                                     <p className="mb-17">Immerse yourself in the joy of genuine conversations with your virtual baby. Receive lifelike<br/> responses like never before in MaAvatar's interactive chat feature.</p>
//                                     <p></p>

//                                     <form action="#" className="widget-search" onSubmit={handleSubmit}>
//                                         <input type="text" placeholder="Say Hi to your baby!" required="" onChange={(e)=>setMessage(e.target.value)} />
//                                         <Link to='#' className="btn-search" type='submit' onClick={handleSubmit} >
//                                             {/* <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                 <circle cx="9.7659" cy="9.76639" r="8.98856" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
//                                                 <path d="M16.0176 16.4849L19.5416 19.9997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
//                                             </svg> */}
//                                             Chat
//                                         </Link>
//                                     </form>
//                                   { (!!botMessage.length || loader) && <div id="your_div"  className='messageWrap' style={{textAlign:'left'}}>
//               {                    loader && <p>Loading....</p>}
// {


//     botMessage && botMessage.reverse().map((item,index)=>{
//     return(
//         <>
//         <p>User:- {item.user}</p>
//         <p>Bot:- {item.bot}</p>
//         </>
//     )
// })
// }
//                                     </div>}
                                    
//                             </div>

                       

                            
//                         </div>

//                     </div>
//                 </div>
//             </section>
//     );
}

export default Chat;