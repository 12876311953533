const menus = [
    {
        id: 1,
        name: 'Register ',
        links: '#register',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Home 01',
        //         links: '/'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Home 02',
        //         links: '/home-v2'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Home 03',
        //         links: '/home-v3'
        //     },

        // ]
    },
    {
        id: 2,
        name: 'Introduction ',
        links: '#register',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'NFTs',
        //         links: '/nfts'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Blog List',
        //         links: '/blog'
        //     },
        //     {
        //         id: 3,
        //         sub: 'Blog Details',
        //         links: '/blog-details'
        //     },
        //     {
        //         id: 4,
        //         sub: 'Visions & Mission',
        //         links: '/visions-mission'
        //     },
        //     {
        //         id: 5,
        //         sub: 'Help Center',
        //         links: '/help-center'
        //     },
        //     {
        //         id: 6,
        //         sub: 'Participants & Assets',
        //         links: '/participants-assets'
        //     },
        //     {
        //         id: 7,
        //         sub: 'Advisors & Backers',
        //         links: '/advisors-backers'
        //     },
        //     {
        //         id: 8,
        //         sub: 'Partners & Investors',
        //         links: '/partners'
        //     },
        // ]
    },
    {
        id: 3,
        name: 'How it works ',
        links: '#how-it-works',
        
    },
    {
        id: 4,
        name: 'Webinar ',
        links: '#webinar',
        
    },
    {
        id: 5,
        name: 'Flagpreneurs ',
        links: '#flagpreneurs',
        
    },
    {
        id: 6,
        name: 'Mentor ',
        links: '#mentor',
    },
    {
        id: 7,
        name: 'FAQs',
        links: '#faq',
    },

    
]

export default menus;