import React from 'react';
import dataBox from '../assets/fake-data/data-box';
import dataFaqs from '../assets/fake-data/data-faq';
import dataPortfolio from '../assets/fake-data/data-portfolio';
import dataRoadMap from '../assets/fake-data/data-roadmap';
import dataTestimonials from '../assets/fake-data/data-testimonials';
import dataProject from '../assets/fake-data/dataProject';
import dataTeam from '../assets/fake-data/dataTeam';
import About from '../components/about/About';
import Banner from '../components/banner/Banner';
import Create from '../components/create/Create';
import Faqs from '../components/faqs/Faqs';
import Footer from '../components/footer/Footer';
import Portfolio from '../components/portfolio/Portfolio';
import Project from '../components/project/Project';
import Roadmap from '../components/roadmap/Roadmap';
import Speciality from '../components/speciality/Speciality';
import Team from '../components/team/Team';
import Testimonials from '../components/testimonials/Testimonials';
import Banner2 from '../components/banner/Banner2';
import dataCard from '../assets/fake-data/data-card';
import About2 from '../components/about/About2';
import Chat from '../components/faqs/Chat';
import Roadmap2 from '../components/roadmap/Roadmap2';
import Faq3 from '../components/faqs/Faq3';
import Team2 from '../components/team/Team2';



function Home01(props) {

    return (
        <div className="home-1 wrapper">
            
            <Banner />

            <About />

            <Banner2 data={dataCard} />

            <Portfolio data={dataPortfolio} />
            {/* <About2 /> */}
            <Chat data={dataFaqs} />
            {/* <Roadmap2 data={dataRoadMap} /> */}
            <div className='raushan' id="flagpreneurs">
            <Team2 data={dataTeam} />
            </div>
            <Faq3 data={dataFaqs} />

            {/* <Testimonials data={dataTestimonials} />


            <Create /> */}

            <Footer />
        </div>
        

    );
}

export default Home01;