import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/layouts/banner.png'
import img2 from '../../assets/images/layouts/pointer.png'
import img3 from '../../assets/images/layouts/avt-01.png'



function Banner(props) {
    const [data, setData] = useState();
    const onChange = (e)=>{
        const {name, value} = e.target;
        setData({...data, [name]:value});
    }
    const onSubmit = (e) =>{
    
        console.log("data", data)
        var payload = {
            "name":data.name,
             "email":data.email,
              "sponsor":"/a",
               "message":"contact from Flagship course",
            "website":"flagship"
        };
        
        fetch('https://api.khubero.com/website/contact', {
     method: 'POST',
     body: JSON.stringify(payload),
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
     },
   }).then(function() {
       alert('Thanks, we will get you soon!!');
   }).then(function(error) {
    console.log("error", error)
   });
    }

    console.log(data);
    return (
        <section className="banner" id="register">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text"> 
                                <p className="desc" style={{marginBottom:"10px"}}>Webinar on</p>
                                    {/* <h2 className="heading">webinar on   <br /></h2> */}
                                    <h2 className="heading">AI And Changing <span className="s1 arlo_tm_animation_text_word"> Career</span>
                                     <br /> Landscape? </h2>
                                    <p className="desc">Get hands on to excluive $50k funding pool on incubator program selection. </p>

                                    {/* <Link to="/contact" className="action-btn"><span>Join the Community</span></Link> */}

                                    <div className='section-1'>
                                    <div  className="banner-form">
                                    <div className="form-group flex">
                                        <input type="name" name="name" className="form-control" placeholder="Name" onChange={onChange} /> 
                                        <input type="email" name="email" className="form-control" placeholder="Your email" onChange={onChange} />
                                        
                                    </div>
                                    <button className="action-btn" onClick={onSubmit}><span>Register</span></button>
                                </div>
                                </div>
                                </div>

                                {/* <div className="pay">
                                    <h6>We are Monteno NFT</h6>

                                    <div className="list">
                                        <p>We accept:</p>

                                        <ul>
                                            <li><Link to="#"><span className="icon-logo-01"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-02"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-03"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-04"></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-05"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></Link></li>
                                            <li><Link to="#"><span className="icon-logo-06"></span></Link></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right">
                                <div className="image">
                                    <img src={img1} alt="cyfonii" />
                                </div>

                                <div className="price">
                                    {/* <div className="icon">
                                        <img src={img2} alt="cyfonii" />
                                    </div> */}
                                    {/* <div className="content">
                                        <p>Daddy!</p>
                                        <h5>Yeahh!</h5>
                                    </div> */}
                                </div>

                                {/* <div className="owner">
                                    <div className="image">
                                        <img src={img3} alt="cyfonii" />
                                    </div>
                                    <div className="content">
                                        <h5>Emily Johnson</h5>
                                        <p>@emily</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;